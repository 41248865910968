<template>
  <v-container fluid ref="top">
    <v-row class="mt-4" align="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <p class="title">
          {{ movementType && movementType === "insert" ? "Inclusão de Grupo Familiar:" : "Implantação:" }}
          Adicionar Titular
        </p>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-stepper tile value="4">
          <v-stepper-header>
            <v-stepper-step step="1" complete color="textPrimary"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" complete color="textPrimary"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" complete color="textPrimary"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" color="textPrimary">Dependentes</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row align="start">
      <v-col cols="12">
        <p class="title">
          Titular:
          <strong>
            {{ contractedPlan && contractedPlan.name ? contractedPlan.name : "" }}
          </strong>
        </p>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-card class="px-6 py-2" elevation="1">
          <v-row class="mt-2">
            <v-col cols="12">
              <p class="title">Adicionar Dependente</p>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  v-model="dependent.document"
                  label="CPF"
                  placeholder="Informe"
                  :rules="dependent.document ? [rule.cpf] : []"
                  validate-on-blur
                  v-mask="'###.###.###-##'"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                <v-text-field
                  v-model.trim="dependent.fullName"
                  label="Nome Completo"
                  maxlength="70"
                  placeholder="Informe"
                  :rules="dependent.fullName ? [rule.validateIsAlphanumeric] : []"
                  outlined
                  color="textPrimary"
                  @blur="dependent.fullName = formatter.formatToTitleCase(dependent.fullName)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    label="Data de Nascimento"
                    v-model="dependent.birthDate"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    color="textPrimary"
                    v-mask="'##/##/####'"
                    :rules="dependent.birthDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                    validate-on-blur
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  v-model="dependent.stateBirth"
                  :items="states"
                  item-text="label"
                  item-value="label"
                  outlined
                  label="UF de Nascimento"
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  :items="genders"
                  v-model="dependent.gender"
                  item-text="description"
                  item-value="id"
                  label="Gênero"
                  outlined
                  color="textPrimary"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                <v-text-field
                  v-model.trim="dependent.motherName"
                  label="Nome da Mãe"
                  maxlength="70"
                  placeholder="Informe"
                  outlined
                  color="textPrimary"
                  :rules="dependent.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
                  validate-on-blur
                  @blur="dependent.motherName = formatter.formatToTitleCase(dependent.motherName)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-autocomplete
                  :items="kinships"
                  v-model="dependent.relationship"
                  item-text="description"
                  item-value="id"
                  label="Grau de Parentesco"
                  outlined
                  color="textPrimary"
                  validate-on-blur
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  v-model="dependent.cns"
                  label="CNS"
                  placeholder="Informe o CNS"
                  v-mask="'###############'"
                  :rules="dependent.cns ? [rule.cns] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  v-model="dependent.dnv"
                  label="Declaração de Nascido Vivo (DNV)"
                  placeholder="Informe o DNV"
                  outlined
                  v-mask="'##-########-#'"
                  :rules="dependent.dnv ? [rule.dnv] : []"
                  validate-on-blur
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  label="Peso(kg)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  placeholder="Informe o peso"
                  v-model="dependent.weight"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="dependent.weight = formatter.removeInvalidNumber(dependent.weight)"
                  @blur="validateNumbersGreaterThanOne(dependent.weight, 'fieldCheckedWeight')"
                  id="fieldCheckedWeight"
                  outlined
                  color="textPrimary"
                  :rules="controlWeight ? ['Favor informar um número válido'] : dependent.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.weight)] : []"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  label="Altura(cm)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  placeholder="Informe a altura"
                  v-model="dependent.height"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="dependent.height = formatter.removeInvalidNumber(dependent.height);"
                  @blur="validateNumbersGreaterThanOne(dependent.height, 'fieldCheckedHeight')"
                  id="fieldCheckedHeight"
                  outlined
                  color="textPrimary"
                  :rules="controlHeight ? ['Favor informar um número válido'] : dependent.height ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.height)] : []"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-autocomplete
                  :items="maritalStatus"
                  v-model="dependent.maritalStatus"
                  label="Estado civil"
                  item-text="description"
                  item-value="id"
                  outlined
                  color="textPrimary"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  label="Complemento de Matrícula"
                  placeholder="Informe o complemento"
                  v-model="dependent.registrationComplement"
                  maxlength="50"
                  outlined
                  color="textPrimary"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="title">Selecione os planos</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn-toggle
                  multiple
                  v-model="dependent.plans"
                  style="width: 100%"
                >
                  <v-row>
                    <v-col v-for="plan in plans" :key="plan.index" cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                      <v-btn outlined dark block :value="plan" color="textPrimary">
                        {{ getPlanName(plan) }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row v-if="documentsList && documentsList.length > 0" align="center" class="mt-10">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <p class="title">Upload de documentos</p>
                  </v-col>
                </v-row>
                <v-row class="mb-3 mx-1" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
                  <v-col v-for="(item, index) in documentsList" :key="index" cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                    <label class="label"> {{ item.description }} </label>
                    <v-file-input
                      :ref="item.name"
                      class="mt-2"
                      v-model="item.file"
                      @change="uploadDocument(item)"
                      placeholder="Clique para adicionar"
                      accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon=""
                      color="textPrimary"
                      outlined
                      :disabled="waitingDocumentUpload"
                      :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
                      :loading="waitingDocumentUpload"
                      :rules="item.file ? [rule.validateUploadFiles(item)] : []"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="my-5 mx-1" justify="end">
            <v-col align="end">
              <v-btn
                class="mr-6 primary--text"
                large
                outlined
                @click="clearDependent()"
                :disabled="waitingDocumentUpload"
                width="240px"
              >
                Limpar campos
              </v-btn>
              <v-btn
                large
                :loading="loadingSave"
                @click="insertNewDependent()"
                :disabled="waitingDocumentUpload"
                class="white--text"
                color="textPrimary"
              >
                <v-icon outlined color="white" class="mr-4">{{ edit.isEdit ? 'fas fa-sync' : 'fas fa-plus' }}</v-icon>
                {{ edit.isEdit ? 'Atualizar Dependente' : 'Adicionar Dependente' }}
              </v-btn>
            </v-col>
          </v-row>
          <v-alert
            transition="fade-transition"
            class="py-4"
            icon="mdi-alert"
            type="error"
            dense
            outlined
            v-model="alert.show"
          >
            {{ alert.text }}
          </v-alert>
          <v-alert
            transition="fade-transition"
            class="py-4"
            icon="mdi-alert"
            v-model="alertNotify.show"
            :color="!alertNotify.type ? 'textPrimary' : ''"
            dense
            outlined
            :type="alertNotify.show ? alertNotify.type : 'error'"
          >
            {{ alertNotify.text }}
          </v-alert>
        </v-card>
        <v-row
          v-for="(person, index) in dependentsList"
          :key="person.document"
          :class="!isCriticizedCarrier ? 'mt-10' : ''"
        >
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-row class="justify-space-between my-2">
              <p class="ml-3 title">Dependente {{ index + 1 }}</p>
              <div>
                <v-btn
                  class="mr-3"
                  outlined
                  color="textPrimary"
                  :disabled="edit.isEdit"
                  @click="setDependent(index, person)"
                >
                  <v-icon small class="mr-4">fas fa-edit</v-icon>
                  Editar
                </v-btn>
                <v-btn
                  class="mr-3"
                  outlined
                  color="textPrimary"
                  :disabled="edit.isEdit"
                  @click="openDeleteDependentDialog(person.document)"
                >
                  <v-icon small class="mr-4">fas fa-trash</v-icon>
                  Excluir
                </v-btn>
              </div>
            </v-row>
            <v-card class="px-6 py-2" elevation="1">
              <v-row class="mt-2">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <p class="title">Dados pessoais</p>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col v-if="person.document" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>CPF: </strong>{{ formatter.formatCpf(person.document) }}</p>
                </v-col>
                <v-col v-if="person.fullName" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Nome completo: </strong> {{ person.fullName }}</p>
                </v-col>
                <v-col v-if="person.birthDate" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Data de nascimento: </strong>{{ (person && person.birthDate) ? formatter.formatDate(person.birthDate) : null }}</p>
                </v-col>
                <v-col v-if="person.stateBirth" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Estado de nascimento: </strong>{{ person.stateBirth }}</p>
                </v-col>
                <v-col v-if="person.gender" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Gênero: </strong>{{ (person && person.gender) && (genders && genders.length > 0) ? formatter.formatGender(person.gender, genders) : null }}</p>
                </v-col>
                <v-col v-if="person.motherName" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Nome da mãe: </strong> {{ person.motherName }}</p>
                </v-col>
                <v-col v-if="person.relationship" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Grau de parentesco: </strong>{{ (person && person.relationship) && (kinships && kinships.length > 0) ? formatter.formatKinship(person.relationship, kinships) : null }}</p>
                </v-col>
                <v-col v-if="person.cns" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>CNS: </strong> {{ person.cns }}</p>
                </v-col>
                <v-col v-if="person.dnv" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Declaração nascido vivo: </strong>{{ person.dnv }}</p>
                </v-col>
                <v-col v-if="person.height" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Altura(cm): </strong> {{ person.height }}cm</p>
                </v-col>
                <v-col v-if="person.weight" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Peso(kg): </strong> {{ person.weight }}kg</p>
                </v-col>
                <v-col v-if="person.maritalStatus" cols="12" xs="12" sm="6" md="4" lg="3">
                  <p><strong>Estado Civil: </strong>{{ (person && person.maritalStatus) && (maritalStatus && maritalStatus.length > 0) ? formatter.formatMaritalStatus(person.maritalStatus, maritalStatus) : null }}</p>
                </v-col>
              </v-row>
              <v-row class="mt-8">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <p class="title">Planos vinculados</p>
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col v-for="plan in person.plans" :key="plan.index" cols="12" xs="12" sm="12" md="4" lg="4">
                  <p>{{ getPlanName(plan) }}</p>
                </v-col>
              </v-row>
              <v-row class="mt-8">
                <v-col cols="12">
                  <p v-if="person && person.documents && person.documents.length > 0" class="title">Documentos Anexados</p>
                </v-col>
              </v-row>
              <v-row class="my-2">
                <v-col
                  v-for="(item, index) in person.documents" :key="index" cols="12" xs="12" sm="12" md="2" lg="2">
                  <v-chip color="primary">
                    {{ item.name }} - {{item.titleDocument}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="violations.length >0">
      <ViolationRulesAlert :violations="violations" />
    </div>
    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            class="white--text"
            color="textPrimary"
            x-large
            block
            :loading="isLoading"
            @click="dependentsList && dependentsList.length > 0 ? onClickFinish() : (confirmationDialog.show = true)"
          >
            Concluir
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <v-dialog v-model="deleteConfirmationDialog.show" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Tem certeza disso? </v-card-title>

        <v-card-text> Essa ação irá excluir o dependente. </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="textPrimary"
            text
            @click="deleteConfirmationDialog.show = false"
          >
            Cancelar
          </v-btn>

          <v-btn color="textPrimary" outlined @click="deleteDependent()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="confirmationDialog.show" persistent max-width="1080">
      <v-card class="pa-5">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="12" align="end">
              <v-icon
                large
                color="textPrimary"
                @click="confirmationDialog.show = false"
                >fas fa-times</v-icon
              >
            </v-col>
            <v-col
              class="text-h4"
              cols="12"
              style="color: #3b495b; font-weight: bold"
              >Você não incluiu dependentes</v-col
            >
            <v-col class="text-h5" cols="12" style="color: #3b495b"
              >Deseja concluir a movimentação assim mesmo?</v-col
            >
            <v-col
              class="text-h6 font-weight-regular"
              cols="12"
              style="color: #3b495b"
              >Você pode incluir dependentes mais tarde</v-col
            >
          </v-row>
        </v-card-title>

        <v-card-actions class="mt-10">
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-3 pa-7 btn-dialog"
              color="#3B495B"
              outlined
              @click="confirmationDialog.show = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              :loading="isLoading"
              style="color: white"
              @click="onClickFinish()"
            >
              OK
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmAddDependentModal" persistent max-width="700">
      <v-card max-height="480" class="overflow-y-auto pa-5">
        <v-card-title>
          <v-row justify="center">
            <v-col cols="12" style="color: #4d596a; font-weight: bold">
              <h4 style="color: #4d596a; word-break: break-word">O CPF informado pertence a um titular</h4>
              <h4 style="color: #4d596a; word-break: break-word">Tem certeza que deseja cadastra-lo como um dependente?</h4>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-row class="mt-6 justify-center">
            <v-btn
              class="mr-3 pa-7 btn-dialog"
              color="#3B495B"
              outlined
              @click="closeConfirmAddDependentModal()"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              style="color: white"
              @click="onClickconfirmAddDependent()"
            >
              Confirmar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmReturnModal" persistent :max-width="600">
      <v-card class="overflow-y-auto pa-5">
        <v-card-title class="pa-5">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-icon large color="green">fa-check</v-icon>
              <v-row class="mb-3" justify="center" style="word-break: break-word">
                <v-col cols="12" align="center">
                  <label class="label primary&#45;&#45;text font-italic" style="word-break: break-word">
                    Movimentação cadastrada com sucesso!
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-row class="ma-1 justify-center">
            <v-btn class="pa-7" color="#3B495B" style="color: white" @click="closeConfirmReturnModal()">
              IR PARA A LISTA DE MOVIMENTAÇÕES
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ReleaseMovementModal
      ref="ReleaseMovementModal"
      @close="closeConfirmReturnModal()"
      @confirm="updateStatus()"
    />

    <InfoMovementDialog
      :show="showReleaseInfo"
      :movementType="movementType && movementType === 'insert' ? 'Inclusão de Grupo Familiar' : 'Implantação'"
      :messageType="releaseMessageType"
      :beneficiaryName="contractedPlan && contractedPlan.name ? formatter.formatToTitleCase(contractedPlan.name) :null"
      @close="closeConfirmReturnModal()"
    />

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="800" :heightCardValue="800">
      <div slot="textInfo">
        <v-row>
          <v-col cols="12">
            <span> {{ alertMessage }} </span>
          </v-col>
        </v-row>
      </div>
    </DynamicDialogInformation>

    <SnackbarCustomize ref="SnackbarCustomize" />

    <v-overlay v-model="overlay">
      <v-progress-circular
        indeterminate
        size="120"
      >
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>

    <div v-if="isCriticizedCarrier">
      <ViewCriticizedViolationDialog />
    </div>

    <ViewCriticismAndInternalNotesModal
      ref="ViewCriticismAndInternalNotesModal"
      @setSnackbarCustomize="setSnackbarCustomize()"
      @confirmCorrectionMovement="confirmCorrectionMovement"
      :authorities="{
        hasAuthorityViewInternalNoteTab,
        hasAuthorityViewButtonSelectedCriticismPending,
        hasAuthorityViewButtonSelectedCriticismResolved
      }"
    />

    <CriticismHandlingModal ref="CriticismHandlingModal" @confirm="verifyMovement" />
  </v-container>
</template>

<script>
import lodash from 'lodash';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import { VMoney } from 'v-money';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import BeneficiaryService from '@/services-http/contract/BeneficiaryService';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import GenderService from '@/services-http/contract/GenderService';
import KinshipService from '@/services-http/contract/KinshipService';
import PhysicalPersonService from '@/services-http/contract/PhysicalPersonService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import { store } from '@/shared/observable/store';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import MovementRecordFormFieldsToValidate from '@/shared/validators/movementRecordFormFieldsToValidate';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import InfoMovementDialog from '@/components/MovementDialog/InfoMovementDialog.vue';
import PayrollService from '@/services-http/sdi/PayrollService';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import ReleaseMovementModal from '@/components/ReleaseMovement/ReleaseMovementModal.vue';
import ViewCriticismAndInternalNotesModal from '@/components/DashboardMovementRecords/Movements/Modals/ViewCriticismAndInternalNotes/ViewCriticismAndInternalNotesModal.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import CriticismHandlingModal from '@/components/CustomAlerts/CriticismHandlingModal.vue';

export default {
  name: 'Dependentes',
  directives: { money: VMoney },
  components: {
    InfoMovementDialog,
    ReleaseMovementModal,
    ViewCriticizedViolationDialog,
    ViolationRulesAlert,
    DynamicDialogInformation,
    SnackbarCustomize,
    ViewCriticismAndInternalNotesModal,
    CriticismHandlingModal,
  },
  data: () => ({
    valid: true,
    maritalStatus: [],
    genders: [],
    kinships: [],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2,
      masked: false,
    },
    plans: [],
    dependent: {
      document: null,
      fullName: null,
      birthDate: null,
      maritalStatus: null,
      gender: null,
      relationship: null,
      cns: null,
      dnv: null,
      motherName: null,
      height: null,
      weight: null,
      plans: [],
      documents: [],
    },
    dependentsList: [],
    documentsList: [],
    confirmationDialog: { show: false },
    deleteConfirmationDialog: {
      show: false,
      id: null,
    },
    alert: {
      show: false,
      text: '',
    },
    alertNotify: {
      show: false,
      text: '',
      type: '',
    },
    edit: {
      isEdit: false,
      id: null,
    },
    contractedPlan: null,
    additionalInfo: null,
    address: null,
    bank: null,
    movementType: null,
    isLoading: false,
    isRHProtegido: false,
    movementTypeId: '',
    insuranceId: '',
    query: '',
    documents: [],
    documentHolder: '',
    listAuxController: [],
    controlHeight: false,
    controlWeight: false,
    planAux: [],
    dependentsListAux: [],
    isBeneficiary: false,
    confirmAddDependentModal: false,
    loadingSave: false,
    documentValidate: '',
    waitingDocumentUpload: false,
    isCriticizedCarrier: false,
    movementRecordId: '',
    movementRecord: {},
    isLoadingValidation: false,
    violations: [],
    confirmReturnModal: false,
    hasAuthorityReleaseMovement: false,
    showReleaseInfo: false,
    successReleaseMessageType: 'RELEASE_SUCCESS',
    errorReleaseMessageType: 'RELEASE_ERROR',
    releaseMessageType: '',
    waitingRulesApprovalIds: [],
    overlay: false,
    alertMessage: 'Ops, algo deu errado',
    hasAuthorityViewInternalNoteTab: false,
    hasAuthorityViewButtonSelectedCriticismPending: false,
    hasAuthorityViewButtonSelectedCriticismResolved: false,
    insertMovement: {},
    skipDuplicityCheck: false,
    persistWithCriticism: false,
  }),

  watch: {
    getObservableStore(val) {
      if (val && !val.show) {
        this.loadDocumentType();
        this.isLoading = false;

        if (val.viewMovements) {
          sessionStorage.setItem('movementRecordIds', JSON.stringify(val.data.movementRecordIds));
          this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
        }

        if (val.skipDuplicityCheck) {
          this.skipDuplicityCheck = true;
          this.onClickFinish();
        }
      }
    },

    'dependent.document': function searchDependentDocument(val) {
      if (val && val.length === 14) {
        if (this.rule.cpf(val)) {
          this.getBeneficiaries();
        }
      }
    },
  },

  async mounted() {
    await this.loadAuthorityMovement();
    this.scrollTo();
    await this.loadDataSessionStorage();
    this.documentHolder = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan')).cpf;
    await this.loadKinships();
    await this.loadGenders();
    await this.loadMaritalStatus();
    await this.loadDocumentType();
  },

  computed: {
    getObservableStore() {
      return store.dialogInformation;
    },
  },

  mixins: [
    ConstantsMixin,
    VerifyRoutesMixin,
    AuthorityManagementMixin,
  ],

  methods: {
    async loadKinships() {
      await this.kinshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.kinships = response.data;
          this.kinships.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadDataSessionStorage() {
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
        this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.movementRecordId = JSON.parse(sessionStorage.getItem('movementRecord')).id;
      }
      if (sessionStorage.getItem('beneficiaryAddress')) {
        this.address = JSON.parse(sessionStorage.getItem('beneficiaryAddress'));
      }
      if (sessionStorage.getItem('beneficiaryBank')) {
        this.bank = JSON.parse(sessionStorage.getItem('beneficiaryBank'));
      }
      if (sessionStorage.getItem('beneficiaryContractedPlan')) {
        this.contractedPlan = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan'));
        this.plans = this.contractedPlan.plans;
        this.plans.forEach((plan) => {
          plan.plans.forEach((p) => {
            if (plan.plan === p.id) {
              // eslint-disable-next-line no-param-reassign
              plan.eligibility = p.eligibility_id;
            }
          });
        });
      }
      if (sessionStorage.getItem('beneficiaryAdditionalInfo')) {
        this.additionalInfo = JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfo'));
      }
      if (sessionStorage.getItem('beneficiaryMovementType')) {
        this.movementType = JSON.parse(sessionStorage.getItem('beneficiaryMovementType'));
      }
      if (sessionStorage.getItem('documents')) {
        this.documents = JSON.parse(sessionStorage.getItem('documents'));
      }
      if (sessionStorage.getItem('dependents')) {
        this.dependent = JSON.parse(sessionStorage.getItem('dependents'));
      }
      if (sessionStorage.getItem('dependentsList')) {
        this.dependentsList = JSON.parse(sessionStorage.getItem('dependentsList'));
      }
    },
    getPlanName(selectPlan) {
      let text = '';
      if (selectPlan && selectPlan.plans && selectPlan.plans.length > 0) {
        selectPlan.plans.forEach((element) => {
          if (element && element.id === selectPlan.plan) {
            text = element.name;
          }
        });
      }
      return text;
    },
    async insertNewDependent() {
      this.loadingSave = true;
      this.scrollTo();
      this.documentValidate = this.formatter.removeNonDigit(this.dependent.document);
      if (this.documentHolder !== this.documentValidate) {
        await this.validationBeneficiary();
        if (this.isBeneficiary) {
          this.openConfirmAddDependentModal();
        } else {
          this.addAndUpdateDependent();
        }
      } else {
        this.setSnackbarCustomize('error', 'Não é possível adicionar o mesmo titular como dependente');
        this.loadingSave = false;
      }
    },
    async validationBeneficiary() {
      if (this.dependent.document) {
        const physicalPersonCPF = this.formatter.removeNonDigit(this.dependent.document);
        await this.beneficiaryService.FindAllByCpf(physicalPersonCPF).then((response) => {
          this.isBeneficiary = response && response.data.length > 0;
        }).catch(() => {
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar um beneficiário');
        });
      }
    },
    addAndUpdateDependent() {
      this.loadingSave = false;
      if (this.$refs.form.validate() && this.dependent.plans.length > 0) {
        this.formatPayload();
        this.dependentsList.push(this.dependent);
        this.alertNotify.show = true;
        this.alertNotify.text = 'Salvo com sucesso!';
        this.alertNotify.type = 'success';
        this.clearDependent();
        this.$refs.form.reset();
        this.closeAlert();
      } else if (this.$refs.form.validate() && this.dependent.plans.length <= 0) {
        this.alert.show = true;
        this.alert.text = 'Nenhum plano selecionado.';
        this.closeAlert();
      } else {
        this.alert.show = true;
        this.alert.text = 'Confira os dados para prosseguir.';
        this.closeAlert();
      }
    },
    setDependent(id, dependent) {
      this.scrollTo();
      this.alertNotify.show = true;
      this.alertNotify.text = 'Edição habilitada.';
      this.alertNotify.type = null;
      this.edit.isEdit = true;
      this.edit.id = id;
      this.dependent = {
        document: this.formatter.formatCpf(dependent.document),
        fullName: dependent.fullName,
        birthDate: this.formatter.formatDate(dependent.birthDate),
        maritalStatus: dependent.maritalStatus,
        gender: dependent.gender,
        relationship: dependent.relationship,
        cns: dependent.cns,
        dnv: dependent.dnv,
        motherName: dependent.motherName,
        height: dependent.height,
        weight: dependent.weight,
        plans: dependent.plans,
        documents: dependent.documents,
        stateBirth: dependent.stateBirth,
        movementRecordParentId: dependent.movementRecordParentId ? dependent.movementRecordParentId : null,
      };
      this.fillOutDocuments(dependent);
      this.dependentsList.splice(id, 1);
      this.closeAlert();
      this.violations = [];
    },
    async fillOutDocuments(dependent) {
      await dependent.documents.forEach((element) => {
        this.documentsList.forEach((item, index) => {
          if (element.name === item.name) {
            this.documentsList[index].file = element.file && element.file.size ? element.file : new File([element.name], element.name, { type: element.type });
          }
        });
      });
    },
    formatPayload() {
      this.dependent.height = this.dependent.height ? Number(this.dependent.height) : '';
      this.dependent.weight = this.dependent.weight ? Number(this.dependent.weight) : '';
      this.dependent.document = this.formatter.removeNonDigit(this.dependent.document);
      const formatDate = this.formatter.formatDateBRtoString(this.dependent.birthDate);
      this.dependent.birthDate = formatDate;
    },
    openDeleteDependentDialog(id) {
      this.deleteConfirmationDialog.show = true;
      this.deleteConfirmationDialog.id = id;
    },
    deleteDependent() {
      this.dependentsList = this.dependentsList.filter((dependent) => dependent.document !== this.deleteConfirmationDialog.id);
      this.deleteConfirmationDialog.id = null;
      this.deleteConfirmationDialog.show = false;
    },
    scrollTo() {
      this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    },
    async clearDependent() {
      this.dependent = {
        document: null,
        fullName: null,
        birthDate: null,
        maritalStatus: null,
        gender: null,
        relationship: null,
        cns: null,
        dnv: null,
        motherName: null,
        height: null,
        weight: null,
        plans: [],
        documents: [],
        registrationComplement: null,
      };

      this.edit.isEdit = false;
      this.edit.id = null;
      await this.findDocumentType(this.query);
    },
    closeAlert() {
      setTimeout(() => {
        this.alert.show = false;
        this.alertNotify.show = false;
      }, 5000);
    },
    clearSessionStorage() {
      const keyToken = '@auth/token';
      const userAuth = '@auth/user';
      const movementRecordIds = 'movementRecordIds';
      const valueToken = sessionStorage.getItem(keyToken);
      const valueUser = sessionStorage.getItem(userAuth);
      const valueMovementRecordIds = sessionStorage.getItem(movementRecordIds);
      sessionStorage.clear();
      if (valueToken && valueUser) {
        sessionStorage.setItem(keyToken, valueToken);
        sessionStorage.setItem(userAuth, valueUser);
      }
      if (valueMovementRecordIds) {
        sessionStorage.setItem('movementRecordIds', movementRecordIds);
      }
    },
    validationFields() {
      if (this.dependent.document
        || this.dependent.fullName
        || this.dependent.birthDate
        || this.dependent.maritalStatus
        || this.dependent.registrationComplement
        || this.dependent.gender
        || this.dependent.relationship
        || this.dependent.cns
        || this.dependent.dnv
        || this.dependent.motherName
        || this.dependent.height
        || this.dependent.weight
        || this.dependent.plans.length > 0
        || this.dependent.documents.length > 0) {
        this.$nextTick(() => {
          this.scrollTo();
        });
        this.alertMessage = 'Para finalizar a movimentação clique em "Adicionar dependente" ou descarte os dados para prosseguir';
        this.$refs.DynamicDialogInformation.open();
        this.confirmationDialog.show = false;
        return true;
      }
      return false;
    },
    async getBeneficiaries() {
      const cpfRequest = this.dependent.document ? this.formatter.removeNonDigit(this.dependent.document) : null;
      const isCpfValid = cpfRequest ? cpfRequest.length === 11 : false;

      if (isCpfValid) {
        await this.getBeneficiaryByPayroll();
      } else {
        await this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPayroll() {
      try {
        const cpfRequest = this.formatter.removeNonDigit(this.dependent.document);
        const queryString = `?documentNumber=${cpfRequest}`;
        const response = await this.payrollService.FindAllByFilters(queryString);
        if (response.data.content && response.data.content.length > 0) {
          this.fillInDependentData(...response.data.content);
        } else {
          await this.getBeneficiaryByPhysicalPerson();
        }
      } catch (error) {
        this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPhysicalPerson() {
      const personCpf = this.dependent.document ? this.formatter.removeNonDigit(this.dependent.document) : null;
      const queryParameters = { cpf: personCpf };

      this.physicalPersonService.FindAllByFilters(queryParameters).then((response) => {
        if (response && response.data && response.data.length > 0) {
          this.fillInDependentData(...response.data);
        }
      });
    },
    fillInDependentData(item) {
      this.dependent.fullName = item.name ? this.formatter.formatToTitleCase(item.name) : null;
      this.dependent.motherName = item.mothersName ? this.formatter.formatToTitleCase(item.mothersName) : null;
      this.dependent.birthDate = item.birthDate ? this.formatter.formatDate(item.birthDate) : null;
      this.dependent.eventDate = item.eventDate ? this.formatter.formatDate(item.eventDate) : null;
      this.dependent.gender = (item.gender && item.gender.id) ? item.gender.id : item.gender ? item.gender : null;
      this.dependent.maritalStatus = (item.maritalStatus && item.maritalStatus.id) ? item.maritalStatus.id : item.maritalStatus ? item.maritalStatus : null;
      this.dependent.registrationComplement = item.registrationComplement ? item.registrationComplement : null;
      this.dependent.stateBirth = item.stateBirth && !item.stateBirth.uf ? item.stateBirth : item.stateBirth && item.stateBirth.uf ? item.stateBirth.uf : null;
      this.dependent.relationship = item.degreeKinship ? item.degreeKinship : null;
      this.dependent.cns = item.cns ? item.cns : null;
      this.dependent.dnv = item.dnv ? item.dnv : null;
    },
    onClickBack() {
      sessionStorage.setItem('dependents', JSON.stringify(this.dependent));
      sessionStorage.setItem('dependentsList', JSON.stringify(this.dependentsList));
      const query = {
        movementType: this.$route.query.movementType,
        isRHProtegido: this.$route.query.isRHProtegido,
        insuranceCarrierId: this.$route.query.insuranceCarrierId,
        beneficiaryType: 'HOLDER',
      };

      this.redirectRouter('AddressBankInformation', query);
    },
    onClickCancel() {
      this.clearSessionStorage();
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    async onClickFinish() {
      if (this.validationFields()) {
        return;
      }

      this.isLoading = true;
      this.overlay = true;
      if (this.movementType === 'implantation') {
        this.payloadForImplantation();
      }
      if (this.movementType === 'alteration') {
        this.payloadForAlteration();
      }
      if (this.movementType === 'insert') {
        this.payloadForInsert();
      }
    },
    payloadForImplantation() {
      this.violations = [];

      let criticsm = [];

      if (sessionStorage.getItem('criticismRules')) {
        criticsm = JSON.parse(sessionStorage.getItem('criticismRules')).filter((movement) => movement.classification && movement.classification === 'INTERNAL').map((movement) => movement.id);
      }

      const implantation = JSON.parse(
        JSON.stringify({
          contractedPlan: this.contractedPlan,
          additionalInfo: this.additionalInfo,
          address: this.address,
          bank: this.bank,
          dependents: this.dependentsList,
          documents: this.documents,
          movementRecordParentId: this.movementRecordId || null,
          internalCriticisms: criticsm,
        }),
      );

      implantation.additionalInfo.height = implantation.additionalInfo.height ? implantation.additionalInfo.height : null;
      implantation.additionalInfo.salary = implantation.additionalInfo.salary ? implantation.additionalInfo.salary.replaceAll(',', '.') : null;
      implantation.additionalInfo.cellphone = implantation.additionalInfo.cellphone ? implantation.additionalInfo.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;
      implantation.additionalInfo.telephone = implantation.additionalInfo.telephone ? implantation.additionalInfo.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;
      implantation.additionalInfo.dnv = implantation.additionalInfo.dnv ? this.formatter.unmaskDnv(implantation.additionalInfo.dnv) : null;
      this.planAux = lodash.cloneDeep(this.plans);
      this.dependentsListAux = lodash.cloneDeep(this.dependentsList);

      if (implantation.contractedPlan && implantation.contractedPlan.plans.length > 0) {
        implantation.contractedPlan.plans.forEach((p) => {
          // eslint-disable-next-line no-param-reassign
          delete p.index;
          // eslint-disable-next-line no-param-reassign
          delete p.eligibilities;
          // eslint-disable-next-line no-param-reassign
          delete p.financialGroup;
          // eslint-disable-next-line no-param-reassign
          delete p.contracts;
          // eslint-disable-next-line no-param-reassign
          delete p.subcontracts;
          // eslint-disable-next-line no-param-reassign
          delete p.plans;
        });
      }

      if (implantation && implantation.dependents && implantation.dependents.length > 0) {
        implantation.dependents.forEach((d) => {
          d.plans.forEach((p) => {
            // eslint-disable-next-line no-param-reassign
            delete p.index;
            // eslint-disable-next-line no-param-reassign
            delete p.eligibilities;
            // eslint-disable-next-line no-param-reassign
            delete p.financialGroup;
            // eslint-disable-next-line no-param-reassign
            delete p.contracts;
            // eslint-disable-next-line no-param-reassign
            delete p.subcontracts;
            // eslint-disable-next-line no-param-reassign
            delete p.plans;
          });
        });
      }

      if (implantation.address) {
        // eslint-disable-next-line no-param-reassign
        delete implantation.address.stateBirth;
      }

      this.confirmationDialog.show = false;
      this.movementRecordService.ValidateImplantation(implantation, this.fieldsToValidate.getFieldsToValidate('dependents')).then(() => {
        this.movementRecordService.RegisterImplantation(implantation).then((response) => {
          const { recordList } = response.data;

          this.waitingRulesApprovalIds = recordList.filter((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS').map((movement) => movement.id);

          const releaseMovementData = {
            beneficiaryName: this.contractedPlan.name,
            movementType: 'Implantação',
          };

          if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
            this.$refs.ReleaseMovementModal.open(releaseMovementData);
          } else {
            this.setSnackbarCustomize('success', 'O cadastro foi implantado com sucesso');
            setTimeout(() => {
              this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
            }, 2500);
          }
        }).catch(() => {
          this.plans = lodash.cloneDeep(this.planAux);
          this.dependentsList = lodash.cloneDeep(this.dependentsListAux);
          this.planAux = [];
          this.dependentsListAux = [];
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao tentar implantar o cadastro');
        });
      }).catch((error) => {
        this.isLoading = false;
        this.overlay = false;
        if (error.response.status === 422) {
          this.violations = [...error.response.data.violations];
        }
      });
    },
    payloadForAlteration() {
      const alteration = {
        contractedPlan: this.contractedPlan,
        additionalInfo: this.additionalInfo,
        address: this.address,
        bank: this.bank,
        dependents: this.dependentsList,
        documents: this.documents,
      };

      alteration.additionalInfo.height = alteration.additionalInfo.height ? alteration.additionalInfo.height : null;
      alteration.additionalInfo.salary = alteration.additionalInfo.salary ? alteration.additionalInfo.salary.replaceAll(',', '.') : null;
      alteration.additionalInfo.dnv = alteration.additionalInfo.dnv ? this.formatter.unmaskDnv(alteration.additionalInfo.dnv) : null;
      this.planAux = lodash.cloneDeep(this.plans);
      this.dependentsListAux = lodash.cloneDeep(this.dependentsList);

      if (alteration.contractedPlan && alteration.contractedPlan.plans && alteration.contractedPlan.plans.length > 0) {
        alteration.contractedPlan.plans.forEach((p) => {
          // eslint-disable-next-line no-param-reassign
          delete p.index;
          // eslint-disable-next-line no-param-reassign
          delete p.eligibilities;
          // eslint-disable-next-line no-param-reassign
          delete p.financialGroup;
          // eslint-disable-next-line no-param-reassign
          delete p.contracts;
          // eslint-disable-next-line no-param-reassign
          delete p.subcontracts;
          // eslint-disable-next-line no-param-reassign
          delete p.plans;
        });
      }

      if (alteration.dependents && alteration.dependents.length > 0) {
        alteration.dependents.forEach((d) => {
          d.plans.forEach((p) => {
            // eslint-disable-next-line no-param-reassign
            delete p.index;
            // eslint-disable-next-line no-param-reassign
            delete p.eligibilities;
            // eslint-disable-next-line no-param-reassign
            delete p.financialGroup;
            // eslint-disable-next-line no-param-reassign
            delete p.contracts;
            // eslint-disable-next-line no-param-reassign
            delete p.subcontracts;
            // eslint-disable-next-line no-param-reassign
            delete p.plans;
          });
        });
      }

      if (alteration.address) {
        // eslint-disable-next-line no-param-reassign
        delete alteration.address.stateBirth;
      }

      this.confirmationDialog.show = false;
      this.movementRecordService.RegisterAlteration(alteration).then(() => {
        this.openConfirmReturnModal();
      }).catch(() => {
        this.plans = lodash.cloneDeep(this.planAux);
        this.dependentsList = lodash.cloneDeep(this.dependentsListAux);
        this.planAux = [];
        this.this.dependentsListAux = [];
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao tentar alterado o cadastro');
      });
    },
    payloadForInsert() {
      this.initializeData();

      const insert = {
        contractedPlan: this.contractedPlan,
        additionalInfo: this.normalizeAdditionalInfo(this.additionalInfo),
        address: this.normalizeAddress(this.address),
        bank: this.bank,
        dependents: this.normalizeDependents(this.dependentsList),
        documents: this.documents,
        movementRecordParentId: this.movementRecordId || null,
        skipDuplicityCheck: this.skipDuplicityCheck,
      };

      this.cleanPlansData(insert.contractedPlan);
      this.cleanPlansDataInDependents(insert.dependents);

      this.confirmationDialog.show = false;

      this.insertMovement = lodash.cloneDeep(insert);
      this.validateAndRegisterInsert();
    },
    initializeData() {
      this.violations = [];
      this.planAux = lodash.cloneDeep(this.plans);
      this.dependentsListAux = lodash.cloneDeep(this.dependentsList);
    },
    normalizeAdditionalInfo(info) {
      return {
        ...info,
        height: info.height ? info.height : null,
        salary: info.salary ? info.salary.replaceAll(',', '.') : null,
        cellphone: info.cellphone ? info.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        telephone: info.telephone ? info.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        dnv: info.dnv ? this.formatter.unmaskDnv(info.dnv) : null,
      };
    },
    normalizeAddress(address) {
      if (address) {
        delete address.stateBirth;
      }
      return address;
    },
    normalizeDependents(dependents) {
      if (dependents && dependents.length > 0) {
        dependents.forEach((dependent) => this.cleanPlansDataInDependents([dependent]));
      }

      return dependents;
    },
    cleanPlansData(plans) {
      if (plans && plans.plans.length > 0) {
        plans.plans.forEach((plan) => this.removeUnnecessaryFields(plan));
      }
    },
    cleanPlansDataInDependents(dependents) {
      if (dependents && dependents.length > 0) {
        dependents.forEach((dependent) => {
          dependent.dnv = dependent.dnv ? this.formatter.unmaskDnv(dependent.dnv) : null;
          if (dependent.plans && dependent.plans.length > 0) {
            dependent.plans.forEach((plan) => this.removeUnnecessaryFields(plan));
          }
        });
      }
    },
    removeUnnecessaryFields(obj) {
      delete obj.index;
      delete obj.eligibilities;
      delete obj.financialGroup;
      delete obj.contracts;
      delete obj.subcontracts;
      delete obj.plans;
    },
    validateAndRegisterInsert() {
      this.movementRecordService.ValidateInsert(this.insertMovement, this.fieldsToValidate.getFieldsToValidate('dependents'))
        .then(() => this.verifyMovement())
        .catch((error) => this.handleInsertError(error));
    },
    verifyMovement(isContinue) {
      this.persistWithCriticism = !!isContinue;
      this.insertMovement = {
        ...this.insertMovement,
        persistWithCriticism: this.persistWithCriticism ? this.persistWithCriticism : false,
      };

      if (this.isCriticizedCarrier) {
        const queryParams = {
          movementRecord: this.movementRecord,
          isRHProtegido: this.isRHProtegido,
          viewType: 'confirmCorrectionMovement',
        };

        this.isLoading = false;
        this.overlay = false;
        this.$refs.ViewCriticismAndInternalNotesModal.open(queryParams);
      } else {
        this.isLoading = true;
        this.overlay = true;
        this.registerInsert();
      }
    },
    confirmCorrectionMovement(data) {
      this.insertMovement = lodash.cloneDeep({
        ...this.insertMovement,
        internalCriticisms: data,
      });
      this.$refs.ViewCriticismAndInternalNotesModal.close();

      this.isLoading = true;
      this.overlay = true;
      this.registerInsert();
    },
    registerInsert() {
      this.movementRecordService.RegisterInsert(this.insertMovement)
        .then((response) => this.handleInsertSuccess(response.data.recordList))
        .catch((error) => this.handleInsertError(error));
    },
    handleInsertSuccess(recordList) {
      const allWithStatusWaitingRulesApproval = recordList.every((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS');

      const releaseMovementData = {
        beneficiaryName: this.contractedPlan.name,
        movementType: 'Inclusão de Grupo Familiar',
      };

      if (this.hasAuthorityReleaseMovement && allWithStatusWaitingRulesApproval) {
        this.waitingRulesApprovalIds = recordList.map((movement) => movement.id);
        this.$refs.ReleaseMovementModal.open(releaseMovementData);
      } else {
        this.openConfirmReturnModal();
      }
    },
    handleInsertError(error) {
      this.plans = lodash.cloneDeep(this.planAux);
      this.dependentsList = lodash.cloneDeep(this.dependentsListAux);
      this.planAux = [];
      this.dependentsListAux = [];
      this.isLoading = false;
      this.overlay = false;

      if (error.response && error.response.status === 422) {
        this.violations = [...error.response.data.violations];

        this.$refs.CriticismHandlingModal.open();
      } else {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao tentar incluir o cadastro');
      }
    },
    checkEmptyFieldAdditionalInformation(additionalInfo) {
      const entries = Object.entries(additionalInfo);
      const updatedEntries = entries.map(([key, value]) => {
        if (value === '' || value === '0.00') {
          return [key, null];
        }
        return [key, value];
      });
      return Object.fromEntries(updatedEntries);
    },
    checkEmptyFieldAddressInformation(address) {
      const entries = Object.entries(address);
      const updatedEntries = entries.map(([key, value]) => {
        if (value === '') {
          return [key, null];
        }
        return [key, value];
      });
      const updatedAddress = Object.fromEntries(updatedEntries);
      if (updatedAddress.log === null) {
        updatedAddress.country = null;
      }
      return updatedAddress;
    },
    checkEmptyFieldDependentsInformation(dependents) {
      let updatedEntries = dependents;
      if (updatedEntries && updatedEntries.length > 0) {
        updatedEntries = updatedEntries.map((value) => {
          const updatedValue = { ...value };
          Object.entries(updatedValue).forEach(([key, val]) => {
            updatedValue[key] = val === '' ? null : val;
          });
          return updatedValue;
        });
      }
      return updatedEntries;
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
        }
      });
    },
    async loadDocumentType() {
      this.movementTypeId = this.$route.query.movementType.toUpperCase();
      this.insuranceId = this.$route.query.insuranceCarrierId;
      const { beneficiaryType } = this.$route.query;
      this.query = `movementType=${this.movementTypeId}&insuranceCarrierId=${this.insuranceId}&beneficiaryType=${beneficiaryType}`;
      await this.findDocumentType(this.query);
    },
    async findDocumentType(query) {
      await this.documentTypeService.FindAllDocumentType(query).then((response) => {
        this.documentsList = response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
        if (this.documentsList && this.documentsList.length > 0) {
          if (sessionStorage.getItem('dependents')) {
            if (this.dependent.documents && this.dependent.documents.length > 0) {
              this.documentsList.forEach((documentList) => {
                this.dependent.documents.forEach((documentDependent) => {
                  if (documentList.name === documentDependent.name) {
                  // eslint-disable-next-line no-param-reassign
                    documentList.file = new File([documentDependent.titleDocument], documentDependent.titleDocument, { type: documentDependent.type });
                  }
                });
              });
            }
          }
        }
      });
    },
    uploadDocument(item) {
      if (item) {
        if ((item.file && (item.file.size > 10485760)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }

        if (item.file !== null && !!item.file) {
          this.validationUploadDocument(item);
        } else {
          this.clearUploadDocument(item, 'clear');
        }
      }
    },
    validationUploadDocument(item) {
      if (item) {
        this.waitingDocumentUpload = true;
        this.documentService.UploadDocument(item.id, item.file).then((response) => {
          const validation = this.dependent.documents.filter((element) => element.name === item.name);
          if (validation.length === 0) {
            this.pushUploadDocument(response, item);
          } else {
            this.clearUploadDocument(validation[0], 'validation');
            this.pushUploadDocument(response, item);
          }
          this.waitingDocumentUpload = false;
        }).catch(() => {
          this.waitingDocumentUpload = false;
        });
      }
    },
    pushUploadDocument(response, item) {
      this.dependent.documents.push({
        id: response.data,
        name: item.name,
        file: item.file,
        titleDocument: item.file.name,
        type: item.file.type,
      });
    },
    clearUploadDocument(document, type) {
      this.dependent.documents.forEach((element, index) => {
        if (type === 'validation') {
          if (element.id === document.id) {
            this.dependent.documents.splice(index, 1);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (element.name === document.name) {
            this.dependent.documents.splice(index, 1);
          }
        }
      });
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    openConfirmAddDependentModal() {
      this.confirmAddDependentModal = true;
    },
    closeConfirmAddDependentModal() {
      this.loadingSave = false;
      this.isBeneficiary = false;
      this.confirmAddDependentModal = false;
    },
    onClickconfirmAddDependent() {
      this.closeConfirmAddDependentModal();
      this.addAndUpdateDependent();
    },
    openConfirmReturnModal() {
      this.confirmReturnModal = true;
    },
    closeConfirmReturnModal() {
      this.$refs.ReleaseMovementModal.close();
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
      this.confirmReturnModal = false;
      this.isLoading = false;
      this.overlay = false;
    },
    async loadAuthorityMovement() {
      this.hasAuthorityReleaseMovement = this.hasPermission('sdi_painel_movimentacoes_cadastrais_liberar_movimentacao');
      this.hasAuthorityViewInternalNoteTab = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_aba_notas_internas');
      this.hasAuthorityViewButtonSelectedCriticismPending = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_acao_marcar_selecionadas_pendentes');
      this.hasAuthorityViewButtonSelectedCriticismResolved = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_acao_marcar_selecionadas_resolvidas');
    },
    async updateStatus() {
      const movementRecordService = new MovementRecordService();
      this.$refs.ReleaseMovementModal.close();

      if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
        const request = {
          ids: [...this.waitingRulesApprovalIds],
          status: 'VALID',
        };

        await movementRecordService.UpdateStatusFromMultipleMovements(request).then(() => {
          this.releaseMessageType = this.successReleaseMessageType;
          this.showReleaseInfo = true;
          this.setSnackbarCustomize('success', 'Movimentação liberada com sucesso');
        }).catch(() => {
          this.releaseMessageType = this.errorReleaseMessageType;
          this.showReleaseInfo = true;
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao liberar a movimentação');
        });
      } else {
        this.releaseMessageType = this.errorReleaseMessageType;
        this.showReleaseInfo = true;
        this.setSnackbarCustomize('error', 'Você não tem permissão para liberar a movimentação');
      }
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
  },

  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.kinshipService = new KinshipService();
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.physicalPersonService = new PhysicalPersonService();
    this.movementRecordService = new MovementRecordService();
    this.fieldsToValidate = new MovementRecordFormFieldsToValidate();
    this.movementType = this.$route.query.movementType;
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.beneficiaryService = new BeneficiaryService();
    this.payrollService = new PayrollService();
  },
};
</script>
<style scoped>
.btn-dialog {
  width: 200px;
  font-size: 16px;
}
</style>
